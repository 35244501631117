<template>
    <div class="left-pane w-54 px-5 py-12  o1">
        <nav class="sub-nav text-purple">
            <a href="#" class="link block border border-transparent my-1 py-4 pl-8 pr-2 rounded-lg"
                :class="{active: uiStates.screen == 'inventory'}"
                @click.prevent="switchScreen('inventory')">

                <img src="https://via.placeholder.com/36x36" alt="" class="inline-block w-9 h-9">
                <span class="t inline-block relative ml-1.5 top-0.5">Inventory</span>
            </a>
            <a href="#" class="link block border border-transparent my-1 py-4 pl-8 pr-2 rounded-lg"
                :class="{active: uiStates.screen == 'activities'}" 
                @click.prevent="switchScreen('activities')">

                <img src="https://via.placeholder.com/36x36" alt="" class="inline-block w-9 h-9">
                <span class="t inline-block relative ml-1.5 top-0.5">Activities</span>
            </a>
        </nav>
    </div>
    <div class="container pt-5 pb-12 o2">
        <Inventory v-if="uiStates.screen == 'inventory'" />
        <Activities v-if="uiStates.screen == 'activities'" />
    </div>
</template>

<script>
import { inject } from "vue";
import Inventory from '@/components/my-account/Inventory.vue';
import Activities from '@/components/my-account/Activities.vue';

const availableScreen = ['inventory', 'activities'];

export default {
    name: 'MyAccount',
    setup() {
        return {
            uiStates: inject('uiStates'),
        };
    },
    created() {
        this.uiStates.page = 'my-account';

        let deep_link = window.location.hash;
        if (deep_link.length > 1) {
            deep_link = deep_link.substr(1);
            console.log('Deep link hash', deep_link);

            const segment = deep_link.split('/');
            console.log('Deep link seg', segment);

            const req_screen = segment[0];
            if (availableScreen.indexOf(req_screen) > -1) {
                this.uiStates.screen = req_screen;   

                if (segment.length > 1) {
                    const req_subScreen = segment[1];
                    if (req_screen == 'inventory') {
                        if (['birds', 'birds-corpse', 'islands', 'eggs'].indexOf(req_subScreen) > -1) {
                            // valid sub screen
                            this.uiStates.subScreen = req_subScreen;
                        }
                    }
                }
            }
            
            
            
        }
    },
    components: {
        Inventory, Activities
    },
    methods: {
        switchScreen(screen) {
            this.uiStates.screen = screen;
            // window.location.hash = '#' + screen;
            // window.history.pushState({}, '', '#' + screen);
        }
    }
}
</script>