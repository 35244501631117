<template>
    <div class="top-pane relative grid grid-cols-2 gap-12.5 w-full">
        <div class="kubird-token bg-black-blueish rounded-xl flex py-5.5 px-7.5">
            <div class="left flex-grow">
                <dt class="block text-sm uppercase text-gray">KUB</dt>
                <dd class="block text-5xl mt-1">{{ tokens.KUB }}</dd>
            </div>
            <div class="icon block w-19 h-19 rounded-half border-2 border-pearl overflow-hidden">
                <img src="https://via.placeholder.com/76x76" class="block w-19 h-19" alt="">
            </div>
        </div>
        <div class="bnb-token bg-black-blueish rounded-xl flex py-5.5 px-7.5">
            <div class="left flex-grow">
                <dt class="block text-sm uppercase text-gray">FAC</dt>
                <dd class="block text-5xl mt-1">
                    <span class="token">{{ tokens.FAC }}</span>
                    <!-- <span class="usd text-lg text-purple ml-2.5">$280,000.33</span> -->
                </dd>
            </div>
            <div class="icon block w-19 h-19 rounded-half border-2 border-pearl overflow-hidden">
                <img src="https://via.placeholder.com/76x76" class="block w-19 h-19" alt="">
            </div>
        </div>
    </div>

    <div class="tab-list pt-4 flex text-purple">
        <a href="#" class="tab-name inline-block border-b-2 py-1.5 px-6 border-transparent" 
            :class="{active: uiStates.subScreen == 'birds'}"
            @click.prevent="switchTab('birds')">
            Birds
        </a>
        <a href="#" class="tab-name inline-block border-b-2 py-1.5 px-6 border-transparent" 
            :class="{active: uiStates.subScreen == 'birds-corpse'}"
            @click.prevent="switchTab('birds-corpse')">
            Birds Corpse
        </a>
        <a href="#" class="tab-name inline-block border-b-2 py-1.5 px-6 border-transparent" 
            :class="{active: uiStates.subScreen == 'islands'}"
            @click.prevent="switchTab('islands')">
            Islands
        </a>
        <a href="#" class="tab-name inline-block border-b-2 py-1.5 px-6 border-transparent" 
            :class="{active: uiStates.subScreen == 'eggs'}"
            @click.prevent="switchTab('eggs')">
            Eggs
        </a>
    </div>

    <div class="tab-contents bg-black-blueish rounded-xl mt-5 p-5 pr-2">

        <div id="birdTab" class="bird-tab tab block styled-scroll overflow-y-auto pr-4" :class="{hidden: uiStates.subScreen != 'birds'}">

            <template v-if="birdsAlive.length > 0">

                <template v-for="(bird) in birdsAlive" :key="'bird-alive-' + bird.id">

                    <div class="bird flex w-full py-2 border-b border-purple text-purple">
                        <div class="bird-shape-wrap w-30 h-20">
                            <img src="https://via.placeholder.com/120x80" class="block" alt="">
                        </div>

                        
                        <div class="brief-stat ml-7.5 w-40 block mt-2 ">
                            <div class="w-full">
                                <div class="bird-id h-4 inline-block rounded-xl text-sm min-w-2/3 px-2 text-black leading-4"
                                    :class="'bg-' + bird.rarity ">#{{ bird.id }}</div>
                                <div class="bird-element relative inline-block w-4 h-4 ml-1 top-1">
                                    <img src="https://via.placeholder.com/15x15" alt="" class="block w-4 h-4">
                                </div>
                            </div>
                            <div class="block pl-2 w-full text-sm mt-1.5 leading-4">
                                <p class="block">TK/Hour: {{ bird.token_gen_base }}/{{ roundDec(bird.token_gen_interval / 60) }}</p>
                                <p class="block">Loyalty: {{ bird.loyalty[0] }}/{{ bird.loyalty[1] }}</p>
                            </div>
                        </div>

                        <div class="body-part flex items-center ml-7.5 w-32">
                            <img src="https://via.placeholder.com/25x25" alt="" class="inline-block w-6 h-6">
                            <span class="t ml-2 text-xs leading-4">Body Element</span>
                        </div>

                        <div class="other-parts grid grid-cols-3 ml-7.5 gap-2.5 w-90 text-xs leading-3.5">
                            <div class="part flex items-center">
                                <img src="https://via.placeholder.com/25x25" alt="" class="inline-block w-6 h-6">
                                <span class="t ml-2">Diamond Crown</span>
                            </div>
                            <div class="part flex items-center">
                                <img src="https://via.placeholder.com/25x25" alt="" class="inline-block w-6 h-6">
                                <span class="t ml-2">Butterfly Wing</span>
                            </div>
                            <div class="part flex items-center">
                                <img src="https://via.placeholder.com/25x25" alt="" class="inline-block w-6 h-6">
                                <span class="t ml-2">Laser Eye</span>
                            </div>
                            <div class="part flex items-center">
                                <img src="https://via.placeholder.com/25x25" alt="" class="inline-block w-6 h-6">
                                <span class="t ml-2">Elithium Beck</span>
                            </div>
                            <div class="part flex items-center">
                                <img src="https://via.placeholder.com/25x25" alt="" class="inline-block w-6 h-6">
                                <span class="t ml-2">TailWind</span>
                            </div>
                            <div class="part flex items-center">
                                <img src="https://via.placeholder.com/25x25" alt="" class="inline-block w-6 h-6">
                                <span class="t ml-2">Dynamic Legs</span>
                            </div>
                        </div>

                        <div class="cta relative ml-7.5 w-32 top-5">
                            <a v-if="birdStatus(bird) == 'selling'" href="#" @click.prevent="cancelOrder('bird', bird)" class="btn block px-4 py-1.5 text-center">Stop Selling</a>
                            <a v-else-if="birdStatus(bird) == 'assigned'" class="btn block px-4 py-1.5 text-center">Feeding</a>
                            <a v-else href="#" @click.prevent="showSellPriceInput('bird', bird)" class="btn block px-4 py-1.5 text-center">Sell</a>
                        </div>

                        <a href="#" class="detail relative inline-block w-4.5 h-4.5 top-7.5 ml-6">
                            <img src="https://via.placeholder.com/18x18" alt="">
                        </a>
                        
                    </div>

                </template>
            </template>
            
            <template v-else>
                <div class="flex w-full bg-black-blueish rounded-xl p-5 justify-center items-center ">
                    <p class="text-pearl text-lg italic">-- Empty --</p>
                </div>
            </template>

            
        </div>

        <div id="birdCorpseTab" class="bird-tab tab block styled-scroll overflow-y-auto pr-4 " :class="{hidden: uiStates.subScreen != 'birds-corpse'}">
            
            <template v-if="birdsDead.length > 0">

                <template v-for="(bird) in birdsDead" :key="'bird-dead-' + bird.id">

                    <div class="bird flex w-full py-2 border-b border-purple text-purple">
                        <div class="bird-shape-wrap w-30 h-20">
                            <img src="https://via.placeholder.com/120x80" class="block" alt="">
                        </div>

                        
                        <div class="brief-stat ml-7.5 w-40 block mt-2 ">
                            <div class="w-full">
                                <div class="bird-id h-4 inline-block rounded-xl text-sm min-w-2/3 px-2 text-black leading-4"
                                    :class="'bg-' + bird.rarity ">#{{ bird.id }}</div>
                                <div class="bird-element relative inline-block w-4 h-4 ml-1 top-1">
                                    <img src="https://via.placeholder.com/15x15" alt="" class="block w-4 h-4">
                                </div>
                            </div>
                            <div class="block pl-2 w-full text-sm mt-1.5 leading-4">
                                <p class="block">TK/Hour: {{ bird.token_gen_base }}/{{ roundDec(bird.token_gen_interval / 60) }}</p>
                                <p class="block">Loyalty: {{ bird.loyalty[0] }}/{{ bird.loyalty[1] }}</p>
                            </div>
                        </div>

                        <div class="body-part flex items-center ml-7.5 w-32">
                            <img src="https://via.placeholder.com/25x25" alt="" class="inline-block w-6 h-6">
                            <span class="t ml-2 text-xs leading-4">Body Element</span>
                        </div>

                        <div class="other-parts grid grid-cols-3 ml-7.5 gap-2.5 w-90 text-xs leading-3.5">
                            <div class="part flex items-center">
                                <img src="https://via.placeholder.com/25x25" alt="" class="inline-block w-6 h-6">
                                <span class="t ml-2">Diamond Crown</span>
                            </div>
                            <div class="part flex items-center">
                                <img src="https://via.placeholder.com/25x25" alt="" class="inline-block w-6 h-6">
                                <span class="t ml-2">Butterfly Wing</span>
                            </div>
                            <div class="part flex items-center">
                                <img src="https://via.placeholder.com/25x25" alt="" class="inline-block w-6 h-6">
                                <span class="t ml-2">Laser Eye</span>
                            </div>
                            <div class="part flex items-center">
                                <img src="https://via.placeholder.com/25x25" alt="" class="inline-block w-6 h-6">
                                <span class="t ml-2">Elithium Beck</span>
                            </div>
                            <div class="part flex items-center">
                                <img src="https://via.placeholder.com/25x25" alt="" class="inline-block w-6 h-6">
                                <span class="t ml-2">TailWind</span>
                            </div>
                            <div class="part flex items-center">
                                <img src="https://via.placeholder.com/25x25" alt="" class="inline-block w-6 h-6">
                                <span class="t ml-2">Dynamic Legs</span>
                            </div>
                        </div>

                        <div class="cta relative ml-7.5 w-32 top-5">
                            <a v-if="birdStatus(bird) == 'selling'" href="#" @click.prevent="cancelOrder('bird', bird)" class="btn block px-4 py-1.5 text-center">Stop Selling</a>
                            <a v-else-if="birdStatus(bird) == 'assigned'" class="btn block px-4 py-1.5 text-center">Feeding</a>
                            <a v-else href="#" @click.prevent="showSellPriceInput('bird', bird)" class="btn block px-4 py-1.5 text-center">Sell</a>
                        </div>

                        <a href="#" class="detail relative inline-block w-4.5 h-4.5 top-7.5 ml-6">
                            <img src="https://via.placeholder.com/18x18" alt="">
                        </a>
                        
                    </div>

                </template>
            </template>
            
            <template v-else>
                <div class="flex w-full bg-black-blueish rounded-xl p-5 justify-center items-center ">
                    <p class="text-pearl text-lg italic">-- Empty --</p>
                </div>
            </template> 
        </div>

        <div id="landTab" class="land-tab tab block styled-scroll overflow-y-auto pr-4" :class="{hidden: uiStates.subScreen != 'islands'}">
            <div class="grid grid-cols-3 gap-5">
                
                <template v-for="land in lands" :key="'land-' + land.id" >

                <div class="card land block border border-purple rounded-xl relative p-2.5 h-58 text-sm">
                    <div class="w-full">
                        <div class="land-id h-4 inline-block rounded-xl text-sm min-w-1/3 px-2 text-black bg-rare leading-4" 
                            :class="'bg-' + land.rarity">#{{ land.id }}</div>

                        <div class="land-elements relative inline-block ml-1 h-4">
                            <img src="https://via.placeholder.com/15x15" alt="" class="inline-block w-4 h-4 mx-1">
                            <img src="https://via.placeholder.com/15x15" alt="" class="inline-block w-4 h-4 mx-1">
                            <img src="https://via.placeholder.com/15x15" alt="" class="inline-block w-4 h-4 mx-1">
                        </div>
                    </div> 

                    <div class="block text-center mt-1">
                        <img src="https://via.placeholder.com/246x160" alt="" class="inline-block">
                    </div>
                    <div class="pos absolute left-4 bottom-2.5 text-white text-lg leading-none">
                        {{ land.pos[0] }} : {{ land.pos[1] }}
                    </div>

                    <div class="status absolute right-2.5 bottom-2.5 leading-none text-pearl">
                        <template v-if="islandStatus(land) === 'Free'">
                            <a href="#" @click.prevent="showSellPriceInput('land', land)" class="btn px-4">Sell</a>
                        </template>
                        <template v-else-if="islandStatus(land) === 'Selling'">
                            <a href="#" @click.prevent="cancelOrder('land', land)" class="btn px-4">Stop Selling</a>
                        </template>
                        <template v-else>
                            {{ islandStatus(land) }}
                        </template>
                        
                    </div>
                </div>
                
                </template>
            </div>
        </div>

        <div id="eggTab" class="egg-tab tab block styled-scroll overflow-y-auto pr-4" :class="{hidden: uiStates.subScreen != 'eggs'}">
            <div class="grid grid-cols-3 gap-5">
                
                <template v-for="egg in eggs" :key="'egg-' + egg.id" >

                <div class="card egg border border-purple rounded-xl relative p-2.5 h-58 text-sm" :data-egg-id="egg.id">
                    <div class="nest-wrap block relative w-28 h-26 mx-auto mt-2 o1">
                        <img src="/imgs/nest/nest.png" width="110" alt="" class="nest absolute bottom-0">
                        <img src="/imgs/nest/egg.png" width="51" alt="" class="egg absolute top-0 left-7.5">
                    </div>
                    <div class="block text-white text-sm text-center mt-2">
                        <p class="status text-2xl">Hatch Egg</p>
                        <p class="sub-title leading-3">You will get a bird</p>
                        <div class="cta block mt-4">
                            <a href="#" @click.prevent="hatchEgg(egg)" class="btn inline-block w-25 h-6 px-4 py-0.5">
                                <img src="https://via.placeholder.com/18x18" alt="" class="inline-block">
                                <span class="t ml-2.5">{{ eggHatchingPrice }}</span>
                            </a>
                        </div>
                        
                    </div>
                </div>

                </template>

                <!-- <div class="card egg border border-purple rounded-xl relative p-2.5 h-58 text-sm">
                    <div class="nest-wrap block relative w-28 h-26 mx-auto mt-2 o1">
                        <img src="/imgs/nest/nest.png" width="110" alt="" class="nest absolute bottom-0">
                        <img src="/imgs/nest/egg.png" width="51" alt="" class="egg absolute top-0 left-7.5">
                    </div>
                    <div class="block text-white text-sm text-center mt-2">
                        <p class="status text-2xl">Hatching</p>
                        <p class="sub-title leading-3">Egg is being hatched...</p>
                        <div class="cta block mt-4">
                            <span class="btn btn-no-hover inline-block w-25 btn-filled h-6 px-4 py-0.5">
                                12:00:00
                            </span>
                        </div>
                        
                    </div>
                </div>

                <div class="card egg border border-purple rounded-xl relative p-2.5 h-58 text-sm">
                    <div class="nest-wrap block relative w-28 h-26 mx-auto mt-2 o1">
                        <img src="/imgs/nest/nest.png" width="110" alt="" class="nest absolute bottom-0">
                        <img src="/imgs/nest/egg.png" width="51" alt="" class="egg absolute top-0 left-7.5">
                    </div>
                    <div class="block text-white text-sm text-center mt-2">
                        <p class="status text-2xl">Completed</p>
                        <p class="sub-title leading-3">Egg was been hatched!</p>
                        <div class="cta block mt-4">
                            <a href="#" class="btn inline-block w-25 h-6 px-4 py-0.5">
                                Collect
                            </a>
                        </div>
                        
                    </div>
                </div> -->

            </div>
        </div>
    </div>

    
    <LightBox ref="priceLightBox" :show-on-created="false" 
        modal-class="w-full mx-2.5 lg:w-60 lg:mx-auto p-5 bg-black bg-opacity-90 rounded-xl text-lg" >

        <form @submit.prevent="submitSale()">
            <label for="sellingPrice" class="block">Selling price:</label>
            <input type="number" id="sellingPrice" autofocus ref="inputBox"
                class="input block w-full mt-3 rounded-md bg-purple-dark py-0.5 px-2 text-center input-reset focus-reset border border-transparent"
                v-model="sellPrice">

            <button type="submit" @click.prevent="submitSale()" class="block w-full mt-3 btn py-0.5 px-4 btn-no-hover">Sell</button>
        </form>
    </LightBox> 
</template>

<script>
import { inject } from "vue";
import LightBox from '@/components/global/LightBox.vue';
import ShareMethods from '@/mixins/ShareMethods.js';
import SMC from '@/helpers/SMC.js'
import API from '@/helpers/Api.js'



export default {
    name: 'Inventory',
    mixins: [ShareMethods],
    components: {
        LightBox
    },
    setup() {
        return {
            uiStates: inject('uiStates'),
            birds: inject('birds'),
            lands: inject('lands'),
            eggs: inject('eggs'),
            user: inject('user'),
            eth: inject('eth'),
            tokens: inject('tokens'),
        };
    },
    data() {
        return {
            selling: false,
            sellType: 'bird',
            sellPrice: '',
            sellItem: null,

            web3: null,
            account: null,

            eggHatchingPrice: 99.99,
        }
    },
    computed: {
        birdsAlive() {
            return this.birds.filter((bird) => bird && bird.soul > 0);
        },
        birdsDead() {
            return this.birds.filter((bird) => bird && bird.soul === 0);
        },
        birdsAssigned() {
            let birds_id = [];
            this.lands.forEach((land) => {
                land.slots.forEach((slot) => {
                    if (slot && slot.birds) {
                        slot.birds.forEach((bird) => {
                            if (bird && bird.id) {
                                birds_id.push(bird.id);
                            }
                        });
                    }
                });
            });

            return birds_id;
        },
    },
    mounted() {

    },
    methods: {
        switchTab(tab) {
            this.uiStates.subScreen = tab;
        },
        birdStatus(bird) {

            if (bird.price) {
                return 'selling';
            }

            if (this.birdsAssigned.indexOf(bird.id) > -1) {
                return 'assigned';
            }

            return 'free';
        },
        islandStatus(land) {
            
            let slot_used = 0;
            let slot_max = 0;
            land.slots.forEach((slot) => {
                if (slot.birds[0] || slot.birds[1]) {
                    slot_used++;
                }
                slot_max ++;
            });

            if (land.price && land.price > 0) {
                return 'Selling';
            }

            if (slot_max == slot_used) {
                return 'Full feeding';
            }

            if (slot_used > 0) {
                return 'Feeding';
            }

            return 'Free';
        },
        async showSellPriceInput(type, item) {
            console.log('Show sell price input');
            
            this.sellType = type;
            this.sellPrice = '';
            this.sellItem = item;
            
            this.$refs.priceLightBox.show();
            setTimeout(() => {
                this.$refs.inputBox.focus();
            }, 300);
        },

        async submitSale() {
            console.log('Submit sale');
            
            let valid = true;
            if ( ! this.sellPrice || isNaN(this.sellPrice)) {
                valid = false;
            }

            let priceReal = parseFloat(this.sellPrice);
            if (isNaN(priceReal)) {
                valid = false;
            }

            if ( ! valid) {
                this.$refs.inputBox.classList.add('border-error');
                this.$refs.inputBox.focus();

                return false;
            } else {
                this.$refs.inputBox.classList.remove('border-error'); 
            }

            this.showLoadingBox();
            console.log(`Selling ${this.sellType}, id #${this.sellItem.id}, price: ${priceReal}`);
            // console.log('SMC birdMarket addr', SMC.addresses.birdMarket);
            
            try {

                let smc_resp = null;
                if (this.sellType === 'bird') {
                    const birdMarketContract = new this.eth.w3.eth.Contract(SMC.abis.birdMarket, SMC.addresses.birdMarket);
          
                    smc_resp = await birdMarketContract.methods.placeOrder(
                        this.sellItem.id, 
                        SMC.ethNum(priceReal, this.eth.w3)
                    ).send({
                        from: this.user.id
                    });

                    console.log('SMC BirdMarketContract.placeOrder resp', smc_resp);
                } else if (this.sellType === 'land') {
                    const landMarketContract = new this.eth.w3.eth.Contract(SMC.abis.landMarket, SMC.addresses.landMarket);
                    smc_resp = await landMarketContract.methods.placeOrder(
                        this.sellItem.id,
                        SMC.ethNum(priceReal, this.eth.w3)
                    ).send({
                        from: this.user.id
                    });
                    
                    console.log('SMC BirdLandContract.placeOrder resp', smc_resp);
                }

                if ( ! smc_resp || ! smc_resp.status) {
                    console.error('Fail placeBird/LandToMarket, SMC error', smc_resp);
                    this.hideLoadingBox();
                    this.$refs.priceLightBox.hide();   
                    return false;
                }
                
                if (this.sellType === 'bird') {
                    const be_resp = await API.placeBirdToMarket(this.sellItem.id, priceReal);
                    console.log('BE placeBirdToMarket resp', be_resp);

                    let bird = this.getItemById(this.birds, this.sellItem.id);
                    if (bird) {
                        bird.price = priceReal;
                    } else {
                        console.error('Fail to find bird to update Selling price', this.sellItem.id);
                    }

                } else if (this.sellType === 'land') {
                    const be_resp = await API.placeLandToMarket(this.sellItem.id, priceReal);
                    console.log('BE placeLandToMarket resp', be_resp);

                    let land = this.getItemById(this.lands, this.sellItem.id);
                    if (land) {
                        land.price = priceReal;
                    } else {
                        console.error('Fail to find land to update Selling price', this.sellItem.id);
                    } 
                }

                this.hideLoadingBox();
                this.$refs.priceLightBox.hide();  

            } catch (ex) {
                console.error('Fail placeBirdToMarket', ex);
                this.hideLoadingBox();
                this.$refs.priceLightBox.hide();  
            }
        },

        
        async cancelOrder(type, item) {
            this.showLoadingBox();

            try {
                let smc_resp = null;
                if (type === 'bird') {

                    const birdMarketContract = new this.eth.w3.eth.Contract(SMC.abis.birdMarket, SMC.addresses.birdMarket);
                    smc_resp = await birdMarketContract.methods.cancelOrder(
                        item.id
                    ).send({
                        from: this.user.id
                    })

                    console.log('SMC BirdMarketContract.cancelOrder resp', smc_resp);

                } else if (type === 'land') {

                    const landMarketContract = new this.eth.w3.eth.Contract(SMC.abis.landMarket, SMC.addresses.landMarket);
                    smc_resp = await landMarketContract.methods.cancelOrder(
                        item.id
                    ).send({
                        from: this.user.id
                    });
                    
                    console.log('SMC LandMarketContract.cancelOrder resp', smc_resp);
                }
                
                if ( ! smc_resp || ! smc_resp.status) {
                    console.error('Fail uplaceBird/LandToMarket, SMC error', smc_resp);
                    this.hideLoadingBox(); 
                    return false;
                }

                if (type === 'bird') {
                    const be_resp = await API.unplaceBirdFromMarket(item.id);
                    console.log('BE unplaceBirdFromMarket resp', be_resp);

                    let bird_tmp = this.getItemById(this.birds, item.id);
                    if (bird_tmp) {
                        bird_tmp.price = 0;
                    } else {
                        console.error('Fail to find bird to update Selling price', item.id);
                    }
                } else if (type === 'land') {
                    const be_resp = await API.unplaceLandFromMarket(item.id);
                    console.log('BE unplaceLandFromMarket resp', be_resp);

                    let tmp = this.getItemById(this.lands, item.id);
                    if (tmp) {
                        tmp.price = 0;
                    } else {
                        console.error('Fail to find land to update Selling price', item.id);
                    } 
                }

                this.hideLoadingBox();
                
            } catch (ex) {
                console.error('Fail unplaceBird/LandFromMarket', ex);
                this.hideLoadingBox();  
            } 
        },

        async hatchEgg(egg) {
            this.showLoadingBox();

            try {
                const mainTokenContract = new this.eth.w3.eth.Contract(SMC.abis.mainToken, SMC.addresses.mainToken)
                const smc_resp = await mainTokenContract.methods.approve(
                    SMC.addresses.farm, (this.eggHatchingPrice * Math.pow(10, 18)).toString()
                ).send({
                    from: this.user.id
                });
                console.log('Metamask approval resp', smc_resp);

                // Calling backend
                const be_resp = await API.hatchEgg(egg.id, smc_resp.transactionHash);
                console.log('BE hatchEgg resp', be_resp);

                if (be_resp) {
                    // assign new bird
                    this.birds.push(be_resp);

                    // remove corrent egg
                    const egg_index = this.getIndexById(this.eggs, egg.id);
                    if (egg_index > -1) {
                        this.eggs.splice(egg_index, 1);
                    } else {
                        console.error('Sth wrong, cant find the egg id in eggs list', egg.id);
                    }

                }

                this.hideLoadingBox();
                
            } catch (ex) {
                console.error('Fail to hatch egg', ex);
                this.hideLoadingBox();
            }
        },
    },
}
</script>