<template>
    <div class="activities flex bg-black-blueish rounded-xl p-5 justify-center items-center ">
        <p class="text-pearl text-lg">No activity yet</p>
    </div>
</template>

<style lang="postcss" scoped>

.activities {
    height: calc(100vh - 168px); /* 168 = top nav 100 + content pad Y 68 */
}
</style>